.noteForm {
  background-color: #1d3656;
  color: #dedede;
  padding: 15px;
  max-width: 300px;
}


.noteForm textarea,
.noteForm label,
.noteForm input {
  width: 250px;
  font-size: 18px;
}

label {
  display: block;
  margin-bottom: .5em;
  margin-top: 1em;
}

.note-button {
  display: block;
  margin-top: 1.5em;
  height: 35px;
  width: 125px;
  border-radius: 5px;
  font-weight: 600;
  border: none;
  font-size: 16px;
}

.note-button:hover:enabled,
.note-button:active:enabled,
.note-button:focus:enabled {
  background-color: #29862e;
  color: #dedede;
  cursor: pointer;
}
